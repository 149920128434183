import React from "react";
import shoutimg from "../../../assests/images/others/Group 1771.png";
import pdf1 from "../../../assests/images/pdf/quest indica.pdf";
import pdf2 from "../../../assests/images/pdf/BBA& BA LL.B Advertisement.pdf";
import pdf3 from "../../../assests/images/others/FDP.pdf";
import pdf4 from "../../../assests/images/others/annNotice2.pdf";
import pdf5 from "../../../assests/images/others/annNotice3.pdf";
import pdf6 from "../../../assests/images/others/Workshop on Financial Planning by Dr. Ravi Ahuja - 03-07-2024.png";
import pdf7 from "../../../assests/images/others/Academic Excellence Award Presentation Ceremony.pdf";
import pdf8 from "../../../assests/images/others/Celebration of National Librarian’s Day, 2024.pdf";
import pdf9 from "../../../assests/images/others/revoluation justice workshot section 1.jpg";
import pdf10 from "../../../assests/images/others/revoluation workshop justice section2.jpg";
import pdf11 from "../../../assests/images/others/Revoluation justic workshop section 3.jpg";

import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Announcement() {
  const noticeData = [
    {
      id: 1,
      notice: "3rd Moot Court Competition Quest Indicia 2024.",
      link: pdf1,
    },
    {
      id: 2,
      notice: "Admissions open for BBA & BA LLB.",

      link: pdf2,
    },
    {
      id: 3,
      notice:
        " FDP- Faculty Development Program (Hybrid Mode) 17 May to 23 May, 2024",

      link: pdf3,
    },
    {
      id: 4,
      notice:
        " Symposium on Pathways to Success for Aspiring Lawyers in the Contemporary Era",

      link: pdf4,
    },
    {
      id: 5,
      notice: "  War & Peace Simulation Competition, 2024",

      link: pdf5,
    },
    {
      id: 6,
      notice: "  Academic Excellence Award Presentation Ceremony",
      link: pdf7,
    },
    {
      id: 7,
      notice: "  Workshop on Financial Planning by Dr. Ravi Ahuja - 03 July 2024",
      link: pdf6,
    },
    {
      id: 8,
      notice: "  Celebration of National Librarian’s Day, 2024",
      link: pdf8,
    },
    {
      id: 9,
      notice: "Revoluation justic workshop section 1 ",
      link: pdf9,
    },
    {
      id: 10,
      notice: "  Revoluation justic workshop section 2",
      link: pdf10,
    },
    {
      id: 11,
      notice: "  Revoluation justic workshop section 3",
      link: pdf11,
    },
  ];
  noticeData.sort((a, b) => b.id - a.id);
  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div className="headerdiv3 ">
        <img src={shoutimg} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Announcements</h2>
          <Link to="/notices" className="linerm">
            <p className="mb-0">
              All Events{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event p-3">
        {noticeData.map((notice, index) => (
          <p className="notice py-2" key={index}>
            <a
              href={notice.link}
              onClick={() => handleDownload(notice.link)}
              className="linerm"
              target="_blank"
            >
              {notice.notice}
              <span className="mx-1">
                <Badge bg="danger">New</Badge>
              </span>
            </a>
            {notice.btn && <span className="notice-btn">{notice.btn}</span>}
          </p>
        ))}
      </div>
    </div>
  );
}
